<template>
  <Card :title="title">
    <template v-slot:body>
      <b-table :data="data" :columns="columns"/>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Cards/Common/Card'

export default {
  name: 'SummaryCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  components: { Card },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <h1 class="title is-1">Összesítő</h1>
    <div class="columns is-multiline">
      <div v-for="(table, index) in summary"
           :key="`summaryCard_${table.name}_${index}`"
           class="column is-12">
        <SummaryCard :title="table.name"
                     :columns="table.table_data.columns"
                     :data="table.table_data.data"/>
      </div>
    </div>
    <div class="loading-container">
      <BasicLoading v-if="isLoading"
                    :is-active="true"/>
    </div>
  </div>
</template>

<script>

import SummaryCard from '@/components/Cards/SummaryCard'
import BasicLoading from '@/components/Loading'

export default {
  name: 'InspectionSummary',
  components: { BasicLoading, SummaryCard },
  data () {
    return {
      isLoading: true,
      summary: [],
    }
  },
  async beforeCreate () {
    this.isLoading = true
    const resp = await this.$store.dispatch('fetchInspectionSummary', this.$route.params.inspectionID)
    if (resp) {
      this.summary = resp
    }
    this.isLoading = false
  },
}
</script>

<style scoped>
.loading-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 400px;
  min-height: 400px;
}
</style>
